import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import styles from './404.module.css';

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not found" />
		<div className={styles.wrapper}>
			<h1>NOT FOUND</h1>
			<p>The page you have requested doesn't exist</p>
		</div>
	</Layout>
);

export default NotFoundPage;
